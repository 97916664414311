const Data = [
    {
        name: "Hrishikesh Tiwari",
        position: "",
        imageUrl: "/assets/images/members/Heads24/Hrishikesh Tiwari.jpg",
        facebookLink: "https://www.facebook.com/profile.php?id=61555836611584",
        linkedinLink: "https://www.linkedin.com/in/hrishikesh-tiwari-338524252?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        email: "hrishikesh.tiwari@gyws.org"
    },
    {
        name: "Suchismita Sahoo",
        position: "",
        imageUrl: "/assets/images/members/Heads24/Suchismita Sahoo.jpg",
        facebookLink: "https://www.facebook.com/profile.php?id=61550090230775",
        linkedinLink: "https://www.linkedin.com/in/suchismita-s-5282072ab?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        email: "suchismita.sahoo@gyws.org"
    },
    
    
]

export default Data;