const Data =[
    
    {
        name: "Nikhil Lahane",
        position: "Chief Executive Officer, LiGHT",
        imageUrl: "/assets/images/members/NikhilLahane.jpg",
        facebookLink: "https://www.facebook.com/nikhil.lahane.143",
        linkedinLink: "https://www.linkedin.com/in/nikhil-lahane-4541641b8?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        email: "nikhil.lahane@gyws.org"
    },
] 

export default Data;