const Data = [
    {
        name: "Ratnesh Khandelwal",
        position: "CEO",
        imageUrl: "/assets/images/members/Ratnesh Khandelwal.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },

]

export default Data;