const Data = [
   
    {
        name: "Priyansh Shrivastava",
        position: "Rise Head",
        imageUrl: "/images/profile.png",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    {
        name: "Manosij Sarkar",
        position: "Rise Head",
        imageUrl: "/images/profile.png",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    {
        name: "Nilesh Nagesh",
        position: "Rise Head",
        imageUrl: "/images/profile.png",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    
]

export default Data;