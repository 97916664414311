const Data = [
    {
        name: "Tanishq Agrawal",
        position: "Sponsorship Head",
        imageUrl: "/assets/images/members/TanishqAgrawal.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    {
        name: "Ankita Khakhal",
        position: "Sponsorship Head",
        imageUrl: "/assets/images/members/AnkitaKhakhal.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    {
        name: "Rudransh Agrawal",
        position: "Sponsorship Head",
        imageUrl: "/assets/images/members/RudranshAgrawal.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    {
        name: "Vishad Gohel",
        position: "Sponsorship Head",
        imageUrl: "/assets/images/members/vishadgohel.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    {
        name: "Sumit Chaudhari",
        position: "Sponsorship Head",
        imageUrl: "/assets/images/members/SumitChaudhari.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    {
        name: "Gourav Kumar Shaw",
        position: "Sponsorship Head",
        imageUrl: "/images/profile.png",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    
]

export default Data;